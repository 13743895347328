import React, { useState, useCallback } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { ProductPreview } from "../utils/product";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TODO: Pagination component can be made into a component
// TODO: handle no products. Throws an error if shopify has no products

const ShopCategory = ({
  data: {
    allShopifyCollection: { nodes: categories },
  },
  pageContext,
}) => {
  const [isActive, setIsActive] = useState(false);

  const toggleDropDown = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const {
    currentPage,
    numberOfPages,
    contextParam,
    limit,
    data: { categories: collections },
  } = pageContext;
  const allProducts = categories.length > 0 ? categories[0].products : [];
  allProducts.sort((a, b) => {
    if (DateTime.fromISO(b.createdAt) < DateTime.fromISO(a.createdAt))
      return -1;
    if (DateTime.fromISO(b.createdAt) === DateTime.fromISO(a.createdAt))
      return 0;
    return 1;
  });

  const products = allProducts.slice(
    (currentPage - 1) * limit,
    (currentPage - 1) * limit + limit
  );

  return (
    <Layout>
      <section className="container">
        <nav
          className="level pagination"
          role="navigation"
          aria-label="pagination"
        >
          <div className="level-left">
            <div className="level-item">
              <p className="title is-3">Shop</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className={isActive ? "dropdown is-active" : "dropdown"}>
                <div className="dropdown-trigger">
                  <button
                    className="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    onClick={toggleDropDown}
                  >
                    <span>
                      {collections.find((c) => c.handle === contextParam).title}
                    </span>
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    <Link className="dropdown-item" to="/shop">
                      All Collections
                    </Link>
                    {collections.map((c) => (
                      <Link
                        key={`dd-key-${c.handle}`}
                        className={
                          c.handle === contextParam
                            ? "dropdown-item is-active"
                            : "dropdown-item"
                        }
                        to={`/shop/${c.handle}`}
                      >
                        {c.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <Link
                className="pagination-previous"
                disabled={currentPage === 1}
                to={`/shop/${currentPage <= 2 ? "" : currentPage - 1}`}
              >
                Previous
              </Link>
              <Link
                className="pagination-next"
                disabled={currentPage === numberOfPages}
                to={`/shop/${
                  currentPage === numberOfPages ? "" : currentPage + 1
                }`}
              >
                Next page
              </Link>
            </div>
          </div>
        </nav>
        <div className="tile is-ancestor">
          {products.slice(0, 3).map((p) => (
            <ProductPreview
              key={`product-${p.handle}`}
              product={p}
              slug="/shop"
            />
          ))}
        </div>
        <div className="tile is-ancestor">
          {products.slice(3, 6).map((p) => (
            <ProductPreview
              key={`product-${p.handle}`}
              product={p}
              slug="/shop"
            />
          ))}
        </div>
        <div className="tile is-ancestor">
          {products.slice(6, 9).map((p) => (
            <ProductPreview
              key={`product-${p.handle}`}
              product={p}
              slug="/shop"
            />
          ))}
        </div>
        <div className="tile is-ancestor">
          {products.slice(9, 12).map((p) => (
            <ProductPreview
              key={`product-${p.handle}`}
              product={p}
              slug="/shop"
            />
          ))}
        </div>
        <div className="tile is-ancestor">
          {products.slice(12, 15).map((p) => (
            <ProductPreview
              key={`product-${p.handle}`}
              product={p}
              slug="/shop"
            />
          ))}
        </div>
        <nav
          className="pagination mb-3"
          role="navigation"
          aria-label="pagination"
        >
          <Link
            className="pagination-previous"
            disabled={currentPage === 1}
            to={`/shop/${currentPage <= 2 ? "" : currentPage - 1}`}
          >
            Previous
          </Link>
          <Link
            className="pagination-next"
            disabled={currentPage === numberOfPages}
            to={`/shop/${currentPage === numberOfPages ? "" : currentPage + 1}`}
          >
            Next page
          </Link>
          <ul className="pagination-list">
            {[...Array(numberOfPages).keys()].map((i) => (
              <li key={`paging-li-${i}`}>
                <Link
                  className={`pagination-link ${
                    currentPage === i + 1 ? "is-current" : ""
                  }`}
                  aria-label={
                    i + 1 === currentPage
                      ? `Page ${i + 1}`
                      : `Goto page ${i + 1}`
                  }
                  to={i > 0 ? `/shop/${i + 1}` : "/shop"}
                  aria-current={i + 1 === currentPage ? "page" : ""}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </Layout>
  );
};

export const shopCategoryQuery = graphql`
  query shopCategoryQuery($contextParam: String!) {
    allShopifyCollection(filter: { handle: { eq: $contextParam } }) {
      nodes {
        products {
          handle
          title
          productType
          variants {
            shopifyId
            price
            title
          }
          featuredImage {
            gatsbyImageData(layout: CONSTRAINED, width: 512)
          }
          media {
            preview {
              image {
                gatsbyImageData(layout: CONSTRAINED, width: 500)
              }
            }
          }
        }
      }
    }
  }
`;

export default ShopCategory;
